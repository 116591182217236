body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  overflow: hidden;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
  color: rgba(255, 0, 221, 0.863);
  font-size: 16px;
  // color: rgba(255, 162, 0, 0.842);
}


.material-icons.inline-valigned {
  font-size: inherit;
  line-height: inherit;
  vertical-align: middle;
  height: auto;
  width: auto;
  margin-bottom: 0.15em;
}

.border-radius-4 {
  border-radius: 4px;
}

.editor-wrapper {
  position: relative;
}

.editor-container {
  position: relative;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 18.5px 14px;
  height: 200px;
  overflow-y: auto;
  resize: vertical;
}

.medium-editor-element img {
  resize: both;
}

.editor-wrapper:focus-within .editor-container {
  border: 2px solid var(--primary);
}

.editor-wrapper:focus-within .label {
  color: var(--primary);
}

.editor-container:hover:not(:focus-within) {
  border-color: #414141
}

.editor-container .medium-editor-element {
  position: relative;
  height: 100%;
}

.editor-container .medium-editor-element blockquote {
  border-left: solid 1px #c4c4c4;
  margin-left: 0;
  padding-left: 0.5rem;
  color: #929292;
}

.editor-container .medium-editor-element a {
  text-decoration: underline;
  color: #7574ed;
}

.medium-editor-element:focus {
  outline: none;
}

.editor-container .medium-editor-placeholder:after {
  display: none;
  font-style: normal !important;
  color: #7f7f7f;
}

.editor-container .medium-editor-element > p {
  margin: 0 0 0.5rem 0;
}

.editor-wrapper > .label {
  position: absolute;
  top: -8px;
  left: 8px;
  display: block;
  padding: 0 5px;
  color: #7f7f7f;
  font-size: 0.75em;
  background-color: #fff;
}

.react-player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
;
  background-color: #000;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.MuiFormControlLabel-label > a {
  color: var(--primary);
  text-decoration: underline;
}

.MuiFormControlLabel-label > a:hover {
  text-decoration: none;
}

.MuiButton-text.profile-button {
  padding: 12px 6px;
}

.MuiButtonBase-root.va-baseline {
  vertical-align: baseline;
}

.MuiButton-text.lang-selector {
  padding: 0;
  background: none;
  min-width: 0;
}

.MuiButton-text.lang-selector:hover {
  background: none;
  color: #cccccc;
}

body.webinar-page .layout1 .content-wrap .scrollbar-container > .content {
  min-height: 100%;
}

.comments-container {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}


body.webinar-page .react-player-wrapper {
  background-color: #000;
}

.react-player-wrapper .whiteboard-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  cursor: none;

}

.react-player-wrapper .whiteboard-wrapper.whiteboard-mode-cursor {
  pointer-events: none;
}

.react-player-wrapper .whiteboard-wrapper.whiteboard-viewer-mode {
  cursor: inherit;
}

body.webinar-page .react-player.react-player-camera {
  z-index: 10;
}

//react-player-camera
body.webinar-page .webinar-mode-whiteboard .react-player.react-player-camera {
  width: 120px !important;
  height: 120px !important;
  position: absolute;
  right: 20px;
  top: 20px;
  left: auto;
  bottom: auto;
}

body.webinar-page .webinar-mode-whiteboard .react-player.react-player-camera > video {
  border-radius: 50%;
}

.whiteboard-cursor {
  width: 10px;
  height: 10px;
  background: #444444;
  border-radius: 50%;
  border: solid 2px rgba(255, 255, 255, 0.5);
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: all 0.2s ease;
  transition-property: background, border, transform;
  transform-origin: 150% 150%;
  z-index: 9999999;
  opacity: 0.8;
  display: none;
}

.whiteboard-color-picker {
  width: 100%;
  max-width: 100px;
  display: flex;
  align-items: normal;
  flex-wrap: wrap;
}

.whiteboard-color-picker .color-button {
  width: 25px;
  height: 25px;
  min-width: 25px;
  max-width: 25px;
  //border:1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  border-collapse: collapse;
  cursor: pointer;
  opacity: 0.8;
}

.whiteboard-color-picker .color-button:hover {
  opacity: 1;
}

.whiteboard-toolbar .MuiButton-root {
  min-width: 24px;
}

.whiteboard-toolbar .MuiButton-outlinedSizeSmall,
.whiteboard-toolbar .MuiButton-containedSizeSmall {
  padding: 4px 5px;
}

.whiteboard-toolbar .MuiSvgIcon-fontSizeSmall {
  font-size: 1.5rem;
}

.whiteboard-toolbar .brush-size-wrapper {
  min-width: 160px;
  display: flex;
  justify-content: flex-start;
  font-size: 1.5rem;
}

.whiteboard-toolbar .brush-size-wrapper .MuiIcon-root {
  font-size: 1.5rem;
  padding: 4px 5px;
}

.whiteboard-toolbar .brush-size-wrapper .MuiSlider-root {
  padding: 14px;
  margin-left: 16px;
  margin-right: 8px;
}

.comments-container .comments-item {
  background-color: #f5f5f5;
  margin-left: 0;
  margin-right: 10%;
  margin-bottom: 20px;
}

.comments-container .comments-item .MuiAvatar-circle {
  width: 30px;
  height: 30px;
}

.comments-container .comments-item .MuiCardHeader-title {
  font-size: 80%;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}

.comments-container .comments-item .MuiCardHeader-subheader {
  font-size: 70%;
}

.comments-container .comments-item .MuiDivider-root {
  //background-color: #e1e1e1;
}

.comments-container .comments-item.own-comment {
  background-color: #08ad6c;
  margin-left: 10%;
  margin-right: 0;
  color: #fff !important;
}

.comments-container .comments-item.own-comment .MuiAvatar-root {
  background-color: #fff;
  color: #08ad6c
}

.comments-container .comments-item.own-comment .MuiTypography-colorTextSecondary {
  color: #fff;
}

.comments-items-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

//
//.social-login-button .MuiButton-label{
//  align-items: center;
//  justify-content: space-around;
//}
.social-login-button {
  //max-width: 320px;
  margin-right: 32px;
  margin-left: 32px;
}

.social-login-button .MuiButton-label > * {
  //display: flex;
  margin-left: 16px;
  margin-right: 16px;
}

.social-login-button .MuiButton-label > .social-login-text {
  min-width: 60px;
  //text-align: left;
}

.mw-320 {
  max-width: 320px;
}


.signup .react-tel-input .country-list {
  max-height: 130px;
  text-align: left;
}


.signup .react-tel-input .form-control:focus {
  border-color: var(--primary);
  box-shadow: 0 0 0 1px var(--primary);
}

.numeric-input input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numeric-input input[type=number] {
  -moz-appearance: textfield;
}


.MuiBox-root .MuiFormControl-root {
  text-align: left;
}

.visual-designer-preview {
  border: solid 1px #ccc;
}

.visual-designer-preview-body h3 {
  padding: 16px 0;
}

.visual-designer-preview p {
  margin-bottom: 16px;
}

.visual-designer-preview-sidebar {
  background-color: #dadada;
}

.visual-designer-preview-sidebar ul {
  padding-left: 16px;
  list-style: none;
}

.logo-preview {
  padding: 16px;
}

.grain-colorPicker-handle {
  width: 100%;
  height: 100%;
  min-width: 20px;
  min-height: 32px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.06), 0px 8px 10px 1px rgba(0, 0, 0, 0.042), 0px 3px 14px 2px rgba(0, 0, 0, 0.036);
  border-radius: 4px;
}

.colorPicker-slider-wrapper {
  width: 300px;
  margin: 16px;
}

.colorPicker-twitter-wrapper .twitter-picker {
  border: none !important;
  box-shadow: none !important;
}

.grain-colorPicker .MuiPopover-paper {

}


.grain-colorPicker-pickers {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.grain-colorPicker-buttons {
  padding: 0 16px 16px 16px;
  text-align: right;
}

.logo-preview img {
  max-height: 100%;
  max-width: 100%;
}

.sortable-blocks-item {
  padding: 24px;
  background-color: #E5E5E5;
  margin-bottom: 0;
  margin-top: 16px;
  position: relative;
  border-radius: 4px;
}

.sortable-blocks-item-remove {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 10px;
  visibility: hidden;
}

.sortable-blocks-item:hover .sortable-blocks-item-remove {
  visibility: visible;
}

.sortable-blocks-item-remove .MuiIcon-root {
  font-size: 0.8rem;
  color: red;
}

.sortable-blocks-item-title {
  cursor: text;
}

.sortable-blocks-item-controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 4px 0;
  font-size: 0.6rem;
}

.sidebar-lesson-title {
  font-size: 1.4rem;
  line-height: 1.2;
  font-weight: 500;
}

.sortable-blocks-item-controls .MuiIcon-root {
  font-size: 1rem;
}

.sortable-blocks-item-controls .MuiButton-root {
  font-size: 0.875rem;
}

.course-content-sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sidebar-lesson-element {
  font-size: 0.875rem;
  line-height: 1.6;
  vertical-align: middle;
  display: flex;
  margin-bottom: 5px;
}

.sidebar-lesson-element .sidebar-lesson-element-title {
  text-decoration: underline;
  cursor: pointer;
}

.sidebar-lesson-element .sidebar-lesson-element-title:hover {
  text-decoration: none;
}

.sidebar-lesson-element .MuiIcon-root {
  font-size: 1rem;
  vertical-align: middle;
  margin-right: 8px;
  margin-top: 3px;
  align-self: baseline;
}

.border-dashed {
  border-style: dashed !important;
}

.MuiIconButton-sizeSmall.plus-minus {
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  vertical-align: middle;
}

.MuiIconButton-sizeSmall.plus-minus .MuiIcon-root {
  font-size: 0.875rem;
}

.plus-minus-label {
  //font-size: 0.875rem;
  font-weight: 500;
  color: #000;
  margin-left: 8px;
}

.plus-minus-label-value {
  margin-right: 0.5rem;
}

.plus-minus-label .toggle-edit {
  text-decoration: underline;
}

.points-input-wrapper {
  font-size: 1rem;
  margin-bottom: 8px;
}


.lesson-sidebar-item .lesson-delete-button {
  visibility: hidden;
}

.lesson-sidebar-item:hover .lesson-delete-button {
  visibility: visible;
}

.lesson-element-item {
  //margin: 16px;
}

.lesson-element-item .MuiButton-root {
  background-color: #E5E5E5;
  font-size: 1rem;
  line-height: 1.4;
  min-height: 160px;
}

.editable-video .react-player-wrapper .edit-controls {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #E5E5E5;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.editable-video .add-video-button {
  text-align: center;
  text-decoration: underline;
  font-size: 1.2rem;
  opacity: 0.9;
}

.editable-video .add-video-button .MuiIcon-root {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.editable-video .react-player-wrapper:hover .add-video-button {
  text-decoration: none;
  opacity: 1;
}

.text-right {
  text-align: right;
}

.text-underline {
  text-decoration: underline;
}

.editable-label > span {
  white-space: pre-line;
  font-size: 1rem;
  cursor: pointer;
}

.editable-label > span .empty {
  opacity: 0.6;
  font-style: italic;
  font-size: 0.8rem;
}

.editable-label:hover > span {
  text-decoration: underline;
}


.editable-label-wrapper {
  position: relative;
}

.editable-label-wrapper .MuiButtonBase-root {
  position: absolute;
  bottom: 5px;
  right: 5px;
  //padding: 5px;
  font-size: 0.7rem;
  color: #727171;
}

.editable-label-wrapper .MuiOutlinedInput-multiline {
  background: #fff;
}

.editable-label-wrapper .MuiButtonBase-root .MuiIcon-root {
  font-size: 1rem;
}

.boolean-selector-label {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 1rem;
}

.grade-input-wrapper .MuiButton-outlined {
  padding: 5px;
  min-width: 32px;
}

.MuiButton-root.MuiButton-text.link-button {
  background: none;
  text-decoration: underline;
  color: #f59c42;
}

.MuiButton-root.MuiButton-text.link-button:hover {
  background: none;
  text-decoration: none;
}

.MuiButton-root.text-left {
  text-align: left;
  justify-content: left;
}

.flow-item {
  padding: 8px 16px;
  background-color: #eeeeee;

}

.text-secondary {
  color: #ff9e43
}

.homework-points .MuiButton-outlinedSizeSmall {
  min-width: 10px;
  margin-right: 8px;
}

.homework-points .MuiButton-contained.bg-dark {
  background-color: #656565;
}

.ql-container.ql-snow {
  background-color: #fff;
}

.layout-contained .container.container-fullWidth {
  max-width: 100%;
}

.tariff-item:hover {
  background-color: #E5E5E5;
}

.lesson-elist-item:after {
  content: ", ";
}

.lesson-elist-item:last-child:after {
  content: "";
}

.html-content > p:first-child {
  margin-top: 0;
}

.hw-task .MuiAvatar-root {
  width: 90px;
  height: 90px;
  font-size: 2.125rem;
  color: #000
}

.hw-task-body {
  font-size: 1.5rem;
  opacity: 0.7;
}

.hw-answer-text {
  font-size: 1.2rem;
  color: #717171;
}

.hw-lector-comment {
  background-color: #eee;
  font-size: 0.9rem;
  color: #000
}

.container-fullHeight {
  display: flex;
  flex-grow: 1;
}

.text-nowrap {
  white-space: nowrap;
}

.react-player-wrapper .play-video-button-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.react-player-wrapper .play-video-button-wrapper .MuiIcon-root {
  color: #fff;
  opacity: 0.6;
  font-size: 5rem;
}

.react-player-wrapper .play-video-button-wrapper .MuiIcon-root:hover {
  text-decoration: none;
  opacity: 1;
}

.course-card-item, .invitation-card-item {
  height: 100%;
}

.question-element-body {
  background-color: #f5f5f5;
  font-size: 1rem;
  max-width: 100%;
}

.question-element-body > p:first-child {
  margin-top: 0;
}

.question-element-body > p:last-child {
  margin-bottom: 0;
}

.html-content a {
  color: var(--primary);
}

.cert-container {
  width: auto;
  //margin-top: 2em;
  overflow: hidden;
}

.cert-outer {
  border: solid 10pt;
  padding: 5pt;
  width: 525pt !important;
  height: 762pt !important;
  text-align: center;
  margin: 10pt auto;
  font-size: 12pt;
}

.cert-content {
  border: solid 2pt;
  height: 100%;
  position: relative;
}

.cert-branding {
  text-align: center;
  margin-top: 50pt;
}

.cert-title {
  font-size: 72pt;
  font-weight: bold;
  text-transform: uppercase;

}

.cert-caption {
  font-size: 18pt;
}

.cert-caption-data {
  font-size: 24pt;
  font-weight: bolder;
}

.cert-sign {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 250pt;
  height: auto;
  font-size: 16pt;
  font-weight: normal;
  padding: 15pt;
  text-align: right;
}

.cert-id {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 250pt;
  height: auto;
  padding: 15pt;
  text-align: left;
}

.tariffs-inline-list > span:after {
  content: ", "
}

.tariffs-inline-list > span:last-child:after{
  content: "";
}